<template>
  <v-container fluid>
    <v-card outlined min-width="500" class="mx-auto">
      <v-card-title primary-title class="primary--text text-uppercase">
        User Information Is Successfully Saved...
      </v-card-title>
      <v-divider></v-divider>
      <!--  <v-virtual-scroll
        :bench="benched"
        :items="name"
        height="390"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.page">
            <v-list-item-content class="body-1">
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn depressed small @click="viewSteppter(item.page)">
                View Saved Details

                <v-icon color="primary" right> mdi-open-in-new </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll> -->
    </v-card>
    <v-container fluid>
      <v-btn color="success" @click="send" class="font-weight-bold">
        Close
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import Files from "@/components/Staff/Create/Files";
  import General from "@/components/Staff/Create/General";
  import Bank from "@/components/Staff/Create/Bank";
  import Employment from "@/components/Staff/Create/Employment";
  import Contact from "@/components/Staff/Create/Contact";
  import Qualification from "@/components/Staff/Create/Qualification";
  import SpouseChildren from "@/components/Staff/Create/SpouseChildren";
  import Parent from "@/components/Staff/Create/Parent";
  import DeathGratuity from "@/components/Staff/Create/DeathGratuity";
  import NextOfKin from "@/components/Staff/Create/NextOfKin";
  export default defineComponent({
    components: {
      Files,
      General,
      Employment,
      Contact,
      Qualification,
      SpouseChildren,
      Parent,
      NextOfKin,
      Bank,
      DeathGratuity,
    },
    setup(props, context) {
      const resultsCard = reactive({
        name: [
          { name: "General", page: 0 },
          { name: "Contact", page: 1 },
          { name: "Bank", page: 2 },
          { name: "Employment", page: 3 },
          { name: "Qualification", page: 4 },
          { name: "Spouse(s) and Children", page: 5 },
          { name: "Parent", page: 6 },
          { name: "Next of Kin", page: 7 },
          { name: "Death Gratuity", page: 8 },
          { name: "Files", page: 9 },
        ],
        benched: 1,
        tab: null,
      });

      const viewSteppter = (page) => context.emit("goto", page);

      const send = () => context.emit("send", true);

      return {
        ...toRefs(resultsCard),
        viewSteppter,
        send,
      };
    },
  });
</script>
