<template>
  <v-card outlined class="pa-4">
    <validation-observer ref="observer">
      <v-form @submit.prevent ref="form1">
        <!-- <v-row>
          <v-col cols="12" lg="6">
            <validation-provider
              v-slot="{ errors }"
              name="Mobile number"
              :rules="{
                required: true,
                digits: 10,
                regex: '^(0)\\d{9}$',
              }"
            >
              <v-text-field
                hide-details="auto"
                outlined
                label="Mobile Number"
                :error-messages="errors"
                v-model="mobileNumber"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6">
            <validation-provider
              v-slot="{ errors }"
              name="Office number"
              :rules="{
                required: true,
                digits: 10,
                regex: '^(0)\\d{9}$',
              }"
            >
              <v-text-field
                hide-details="auto"
                label="Office Number"
                v-model="officeNumber"
                validate-on-blur
                :error-messages="errors"
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <validation-provider
              v-slot="{ errors }"
              name="Personal Email"
              rules="required|email"
            >
              <v-text-field
                hide-details="auto"
                label="Personal Email"
                v-model="personEmail"
                :error-messages="errors"
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6">
            <validation-provider
              v-slot="{ errors }"
              name="Corporate Email"
              rules="required|alpha_dash|alpha_spaces"
              ref="uniqueCorporateEmail"
            >
              <v-text-field
                hide-details="auto"
                label="Corporate Email"
                :error-messages="errors"
                :messages="checkingText"
                @keyup="ifIdUnique"
                v-model="corporateEmail"
                outlined
                suffix="@gctu.edu.gh"
              >
                <template slot="append" v-if="checkingText">
                  <v-progress-circular indeterminate></v-progress-circular>
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Address"
              rules="required"
            >
              <v-text-field
                label="Personal Postal Address"
                outlined
                v-model="address"
                :error-messages="errors"
                hide-details="auto"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row> -->
        <v-row justify="center" align="center">
          <v-col cols="12" md="4" sm="8" lg="5">
            <v-hover v-slot="{ hover }" style="cursor: pointer !important">
              <v-card
                :elevation="hover ? 8 : 2"
                :class="{ 'on-hover': hover }"
                @click="triggerUpload"
                class="mt-2"
                width="300"
                height="300"
                tile
              >
                <v-img contain :aspect-ratio="1 / 1" :src="image">
                  <p
                    v-if="!image"
                    class="d-flex justify-center align-center mt-6"
                    :class="`${hover ? 'primary--text' : 'secondary--text'}`"
                  >
                    CLICK TO ADD PROFILE PICTURE
                  </p>
                </v-img>
                <input
                  class="d-none"
                  ref="uploader"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  @change="uploadImage"
                />
                <v-card-text
                  v-if="!image"
                  :class="{ 'show-btns': hover }"
                  class="title text-center text-wrap px-0"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        primary
                        lighten-2
                        v-card--reveal
                        text-h2
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-btn color="success" icon>
                        <v-icon color="white" x-large>
                          mdi-48px mdi-camera
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col>
            <div>
              <b class="red--text">*</b> The following formats are acceptable:
              jpg, jpeg and png
            </div>
            <div>
              <b class="red--text">*</b> The picture should be 300 x 300 pixels
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              @click="sendData"
              :loading="loading"
              :disabled="loading"
              color="primary"
            >
              Continue
              <template v-slot:loader>
                <span>Saving...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
        <div class="text-center" v-if="sheet">
          <v-bottom-sheet v-model="sheet" persistent>
            <v-sheet class="text-center" height="150px">
              <div class="py-3 body-1">
                Do you want to proceed without a <b>profile picture</b> ?
              </div>
              <v-btn text color="error" @click="sheet = !sheet"> close </v-btn>
              <v-btn
                class="font-weight-bold body-1"
                color="success"
                type="button"
                @click="withoutProfilePix"
              >
                Continue
              </v-btn>
            </v-sheet>
          </v-bottom-sheet>
        </div>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script type="javascript">
  import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";
  import Api from "@/apis/Api";
  // import debounce from "@/debounce/debounce";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    digits,
    regex,
    alpha_dash,
    alpha_spaces,
    required,
    email,
  } from "vee-validate/dist/rules";
  import { useGetters } from "vuex-composition-helpers";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("email", { ...email, message: "{_field_} must be valid" });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be ten digits starting with zero(0)",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { ValidationProvider, ValidationObserver },
    setup(props, context) {
      const vm = getCurrentInstance();
      const contactForm = reactive({
        observer: null,
        valid: true,
        mobileNumber: null,
        officeNumber: null,
        personEmail: null,
        corporateEmail: null,
        address: null,
        checkingText: null,
        uniqueCorporateEmail: null,
        image: null,
        uploader: null,
        sheet: false,
        profilePicture: null,
        loading: false,
      });

      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const store = vm.proxy.$store;

      const { observer, sheet, profilePicture, image, uploader, loading } =
        toRefs(contactForm);

      const uploadImage = (e) => {
        profilePicture.value = e.target.files[0];
        if (!profilePicture.value.type.match("image/*")) {
          alert("Choose an image file");
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(profilePicture.value);
        reader.onload = (e) => {
          image.value = e.target.result;
        };
      };

      const triggerUpload = () => {
        uploader.value.click();
      };

      const withoutProfilePix = async () => {
        loading.value = false;
        context.emit("page", { bool: true, page: 1, name: "contact" });
        sheet.value = false;
      };

      const sendData = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result === true) {
            loading.value = true;
            let formData = new FormData();
            if (image.value === null) {
              sheet.value = true;
              return;
            }
            formData.append("imageDp", profilePicture.value);
            formData.append("user_id", getters_single_staff.value.id);

            Api()
              .post("/user_avatar", formData)
              .then((res) => {
                store.commit("SET_SINGLE_STAFF", res.data.data);
                context.emit("page", { bool: true, page: 1, name: "contact" });
              })
              .catch(() => {
                context.emit("page", { bool: false, page: 1 });
              })
              .finally(() => (loading.value = false));
          } else context.emit("page", { bool: false, page: 1 });
        });
      };

      return {
        ...toRefs(contactForm),
        sendData,
        withoutProfilePix,
        uploadImage,
        triggerUpload,
      };
    },
  });
</script>

<style scoped>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
</style>