<template>
  <v-container fluid>
    <v-card class="pb-3" v-if="items.length > 0">
      <v-virtual-scroll :bench="0" :items="items" height="100" item-height="50">
        <template v-slot:default="{ item, index }">
          <v-list-item :key="item.page">
            <v-list-item-action>
              <v-slide-x-reverse-transition>
                <v-btn
                  icon
                  small
                  color="red"
                  @click="removeRow(items, index, item.filename)"
                >
                  <v-icon dark> mdi-delete</v-icon>
                </v-btn>
              </v-slide-x-reverse-transition>
            </v-list-item-action>

            <v-list-item-content class="body-1">
              <v-list-item-title>
                {{ item.filename }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn link @click="doAction(item.filename)" icon color="primary">
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
    <v-expand-transition>
      <v-card outlined v-if="items.length > 0">
        <v-card-text>
          <!-- <v-slide-y-transition group>
            <v-row
              justify="center"
              align="center"
              v-for="(task, i) in items"
              :key="i"
              class="my-1"
            >
              <v-col cols="12" lg="5">
                <v-btn
                  link
                  @click="doAction(task.filename)"
                  text
                  color="primary"
                >
                  {{ task.filename }}
                </v-btn>
              </v-col>
              <v-col cols="12" lg="1" class="text-center">
                <v-slide-x-reverse-transition>
                  <v-btn
                    icon
                    small
                    color="red"
                    @click="removeRow(items, i, task.filename)"
                  >
                    <v-icon dark> mdi-delete</v-icon>
                  </v-btn>
                </v-slide-x-reverse-transition>
              </v-col>
            </v-row>
          </v-slide-y-transition> -->
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-card
      @drop.prevent="sendFile"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      min-height="200"
      class="pa-2"
      :class="{
        'grey lighten-1': dragover,
        'white--text': dragover,
      }"
      elevation="0"
      color="grey darken-1"
      :style="{ outline: dragover ? '4px dashed white' : outline }"
      style="cursor: pointer; outline-offset: -10px"
    >
      <input
        style="
          opacity: 0;
          width: 100%;
          position: absolute;
          height: 200px;
          cursor: pointer;
        "
        ref="uploader"
        type="file"
        accept="image/*"
        @change="sendFileInput"
        multiple
      />
      <div class="text-center headline" style="padding: 70px 0">
        Drag and drop, or Click to Upload files
        <div class="text-center">
          <v-icon x-large :class="{ 'white--text': dragover }">
            mdi-cloud-upload
          </v-icon>
        </div>
      </div>
    </v-card>

    <p class="body-1">
      Acceptable File Types:
      <span class="font-weight-bold"> .pdf, .jpeg, .jpg and .png only</span>
    </p>

    <v-row justify="center" v-if="uploadedFiles.length > 0">
      <v-col
        cols="12"
        md="4"
        lg="4"
        v-for="(preview, index) in uploadedFiles"
        :key="index"
      >
        <v-sheet
          auto-height
          class="v-sheet--offset mx-auto"
          color="white"
          max-width="calc(100% - 20px)"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-img class="" :src="preview.pix" :aspect-ratio="16 / 9">
                <v-expand-transition :key="index">
                  <div class="d-flex justify-space-between">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="removeImage(preview.name)"
                          absolute
                          icon
                          v-bind="attrs"
                          v-on="on"
                          right
                          small
                          class="mt-1"
                          :class="{ 'show-btns': hover }"
                          :color="transparent"
                        >
                          <v-icon
                            :color="transparent"
                            :class="{ 'show-btns': hover }"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Click to delete</span>
                    </v-tooltip>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="validate"
        >
          Save and Continue
          <template v-slot:loader>
            <span>Saving...</span>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, getCurrentInstance, ref } from "vue";
  import Api from "@/apis/Api";
  import { useGetters } from "vuex-composition-helpers";
  export default defineComponent({
    props: ["user_id"],
    setup(props, context) {
      const vm = getCurrentInstance()
      const store = vm.proxy.$store;
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const uploading = ref(false);
      const files = ref([]);
      const uploadedFiles = ref([]);
      const dragover = ref(false);
      const transparent = ref("rgba(255, 255, 255, 0)");
      const outline = "4px dashed #424242";
      const uploader = ref(null);
      const items = ref([]);
      const formData = new FormData();
      const deletedItems = ref([]);
      const loading = ref(false);

      const sendFile = (e) => {
        console.log(files.value);
        try {
          files.value = e.dataTransfer.files;
          dragover.value = false;

          for (let file of files.value) {
            let i = 0;
            formData.append("documents[" + i++ + "]", file);
            let fileType = file.type;
            let validType = [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ];

            let condition = validType.indexOf(fileType) === -1;

            if (condition) {
              alert("error");
              return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
              uploadedFiles.value.push({
                name: file.name,
                size: file.size,
                pix: e.target.result,
              });
            };
          }
        } catch (error) {
          console.log(error);
        }
      };

      const sendFileInput = (e) => {
        files.value = e.target.files;
        let x = 0;
        for (let i of files.value) {
          formData.append("documents[" + x++ + "]", i);
          const reader = new FileReader();
          reader.readAsDataURL(i);
          reader.onload = (e) => {
            uploadedFiles.value.push({
              name: i.name,
              size: i.size,
              pix: e.target.result,
            });
          };
        }

        uploader.value = "";
      };

      const removeImage = (img) => {
        const index = uploadedFiles.value.findIndex((item) => item.name === img);
        if (index > -1) uploadedFiles.value.splice(index, 1);
      };

      const validate = async () => {
        if (uploadedFiles.value.length > 0) {
          loading.value = true;
          formData.append("deletedItems", JSON.stringify(deletedItems.value));
          formData.append("user_id", getters_single_staff.value.id);

          await Api()
            .post("/user_document", formData)
            .then((res) => {
              store.commit("SET_SINGLE_STAFF", res.data.data);
              items.value = getters_single_staff.value.documents;

              context.emit("page", { bool: true, page: 9 });
            })
            .catch((e) => {
              context.emit("page", { bool: false, page: 9 });
              console.log(e);
            })
            .finally(() => (loading.value = false));
        } else {
          context.emit("page", { bool: false, page: 9 });
        }
      };

      const doAction = (item) => {
        window.open(
          `http://localhost:8000/${getters_single_staff.value.id}/${item}`,
          "_blank"
        );
      };

      const removeRow = (array, i, filename) => {
        array.splice(i, 1);
        if (filename) {
          deletedItems.value.push(filename);
          return;
        }
      };

      return {
        removeRow,
        doAction,
        uploading,
        sendFile,
        sendFileInput,
        transparent,
        uploadedFiles,
        files,
        dragover,
        removeImage,
        validate,
        outline,
        items,
        loading,
      };
    },
  });
</script>
<style scoped>
  .v-card {
    transition: opacity 0.4s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.6;
  }

  .show-btns {
    color: #002e69 !important;
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 1) 5px,
      rgba(206, 206, 206, 0.25) 20px
    );
  }
</style>
