<template>
  <v-card flat>
    <validation-observer ref="observer">
      <v-form @submit.prevent>
        <v-card outlined class="pa-4 mb-2">
          <v-row>
            <v-col cols="12" lg="3">
              <validation-provider
                v-slot="{ errors }"
                name="Spouse"
                rules="required"
              >
                <v-select
                  outlined
                  hide-details="auto"
                  :items="spouseBool"
                  label="Spouse"
                  v-model="spouseStatus"
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>

          <v-slide-y-transition>
            <div class="mt-3" v-if="showSpouse">
              <v-row>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Spouse"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Name of spouse"
                      v-model="spouseName"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-menu
                    v-model="menuSpouse"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Spouse date of birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="spouseDob"
                          label="Date of birth"
                          readonly
                          outlined
                          hide-details="auto"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="spouseDob"
                      @input="menuSpouse = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hometown"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Hometown"
                      v-model="spouseHometown"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile number 1"
                    :rules="{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Phone number 1"
                      v-model="spouseNumber1"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile number 2"
                    :rules="{
                      regex: spouseNumber2 ? '^(0)\\d{9}$' : '',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Phone number 2 (optional)"
                      v-model="spouseNumber2"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date of birth"
                    rules="required"
                  >
                    <v-text-field
                      hide-details="auto"
                      outlined
                      label="Address"
                      :error-messages="errors"
                      v-model="spouseAddress"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
          </v-slide-y-transition>
        </v-card>

        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Child"
                  rules="required"
                >
                  <v-select
                    outlined
                    hide-details="auto"
                    :items="birthBool"
                    label="Child/Children"
                    v-model="birthStatus"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-slide-y-transition>
              <div class="mt-3" v-if="showChildren">
                <template v-for="(task, i) in basketChildren">
                  <v-row justify="center" align="center" :key="i">
                    <v-col cols="12" lg="4">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.name"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          outlined
                          hide-details="auto"
                          label="Name of child"
                          v-model="task.name"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-menu
                        v-model="task.menuDob"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="task.dob"
                            rules="required"
                          >
                            <v-text-field
                              v-model="task.dob"
                              label="Date of birth"
                              readonly
                              outlined
                              hide-details="auto"
                              :error-messages="errors"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="task.dob"
                          @input="task.menuDob = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.birthplace"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model="task.birthplace"
                          :error-messages="errors"
                          label="Birthplace"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.relationship"
                        rules="required|alpha"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          v-model="task.relationship"
                          :error-messages="errors"
                          label="Relationship"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="2" class="text-center">
                      <v-slide-y-reverse-transition>
                        <v-btn
                          icon
                          dark
                          v-if="i + 1 === basketChildren.length"
                          color="success"
                          @click="newRow()"
                          :disabled="!valid"
                        >
                          <v-icon dark>mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-slide-y-reverse-transition>
                      <v-slide-y-reverse-transition>
                        <v-btn
                          v-if="i + 1 >= 2"
                          dark
                          icon
                          color="red"
                          @click="removeRow(i)"
                        >
                          <v-icon dark> mdi-delete-outline </v-icon>
                        </v-btn>
                      </v-slide-y-reverse-transition>
                    </v-col>
                  </v-row>
                </template>
              </div>
            </v-slide-y-transition>
          </v-card-text>
        </v-card>
      </v-form>
    </validation-observer>

    <v-card-actions class="ml-2">
      <v-btn
        v-if="!props.user_id"
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="sendData"
      >
        Save and Continue
        <template v-slot:loader>
          <span>Saving...</span>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
      <v-btn color="success" @click="addNewSpouseChildren" v-else>
        save changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import { defineComponent, reactive, toRefs, watch } from "vue";

  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { regex, required } from "vee-validate/dist/rules";

  extend("regex", {
    message: "{_field_}  must be ten digits starting with zero(0)",
  });

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props, context) {
      const { userSpouseChildren } = useActions(["userSpouseChildren"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const spouseChildrenForm = reactive({
        observer: null,
        valid: true,
        basketChildren: [
          { name: "", dob: "", birthplace: "", relationship: "", menuDob: false },
        ],
        birthBool: ["Yes", "No"],
        spouseBool: ["Yes", "No"],
        menuSpouse: false,
        spouseStatus: null,
        birthStatus: null,
        showChildren: false,
        showSpouse: false,
        spouseName: null,
        spouseDob: null,
        spouseAddress: null,
        spouseNumber1: null,
        spouseNumber2: null,
        spouseHometown: null,
        basketSpouse: null,
        menuDob: false,
        loading: false,
      });

      const {
        loading,
        basketChildren,
        observer,
        birthStatus,
        showChildren,
        spouseStatus,
        spouseDob,
        spouseName,
        showSpouse,
        spouseAddress,
        spouseNumber1,
        spouseNumber2,
        spouseHometown,
        basketSpouse,
      } = toRefs(spouseChildrenForm);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            loading.value = true;
            if (spouseStatus.value === "Yes") {
              basketSpouse.value = {
                spouse_name: spouseName.value,
                spouse_dob: spouseDob.value,
                spouse_address: spouseAddress.value,
                spouse_number1: spouseNumber1.value,
                spouse_number2: spouseNumber2.value,
                spouse_hometown: spouseHometown.value,
              };
            }

            if (birthStatus.value === "No") {
              basketChildren.value = [];
            }

            let data = {
              children: basketChildren.value.map((item) => ({
                name: item.name,
                dob: item.dob,
                birthplace: item.birthplace,
                relationship: item.relationship,
                user_id: getters_single_staff.value.id,
              })),
              spouse: basketSpouse.value,
              user_id: getters_single_staff.value.id,
            };
            userSpouseChildren(data)
              .then(() =>
                context.emit("page", {
                  bool: true,
                  page: 5,
                  name: "spousechildren",
                })
              )
              .catch(() => context.emit("page", { bool: false, page: 5 }))
              .finally(() => (loading.value = false));
          } else context.emit("page", { bool: false, page: 5 });
        });
      };

      const addNewSpouseChildren = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            if (spouseStatus.value === "Yes") {
              basketSpouse.value = {
                spouse_name: spouseName.value,
                spouse_dob: spouseDob.value,
                spouse_address: spouseAddress.value,
                spouse_number1: spouseNumber1.value,
                spouse_number2: spouseNumber2.value,
                spouse_hometown: spouseHometown.value,
              };
            }

            if (birthStatus.value === "No") {
              basketChildren.value = [];
            }

            let data = {
              children: basketChildren.value.map((item) => ({
                name: item.name,
                dob: item.dob,
                birthplace: item.birthplace,
                relationship: item.relationship,
                user_id: props.user_id,
              })),
              spouse: basketSpouse.value,
              id: props.user_id,
            };
            userSpouseChildren(data);
          }
        });
      };

      watch(
        () => spouseStatus.value,
        (bool) => {
          if (bool === "Yes") {
            showSpouse.value = true;
            spouseName.value = null;
            spouseDob.value = null;
            spouseAddress.value = null;
            spouseNumber1.value = null;
            spouseNumber2.value = null;
            spouseHometown.value = null;
            return;
          }
          showSpouse.value = false;
        }
      );

      watch(
        () => birthStatus.value,
        (bool) => {
          if (bool === "Yes") {
            showChildren.value = true;
            basketChildren.value = [
              { name: "", dob: "", birthplace: "", relationship: "" },
            ];
            return;
          }
          showChildren.value = false;
        }
      );

      const newRow = () => {
        basketChildren.value.push({
          name: "",
          dob: "",
          birthplace: "",
          relationship: "",
        });
      };

      const removeRow = (i) => {
        basketChildren.value.splice(i, 1);
      };

      return {
        ...toRefs(spouseChildrenForm),
        sendData,
        newRow,
        removeRow,
        props,
        addNewSpouseChildren,
      };
    },
  });
</script>
