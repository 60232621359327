var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',{staticClass:"pa-4 mb-2",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('validation-provider',{attrs:{"name":"Spouse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","hide-details":"auto","items":_vm.spouseBool,"label":"Spouse","error-messages":errors},model:{value:(_vm.spouseStatus),callback:function ($$v) {_vm.spouseStatus=$$v},expression:"spouseStatus"}})]}}])})],1)],1),_c('v-slide-y-transition',[(_vm.showSpouse)?_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Spouse","rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Name of spouse","error-messages":errors,"outlined":""},model:{value:(_vm.spouseName),callback:function ($$v) {_vm.spouseName=$$v},expression:"spouseName"}})]}}],null,false,2209500464)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Spouse date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.spouseDob),callback:function ($$v) {_vm.spouseDob=$$v},expression:"spouseDob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,3025607610),model:{value:(_vm.menuSpouse),callback:function ($$v) {_vm.menuSpouse=$$v},expression:"menuSpouse"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuSpouse = false}},model:{value:(_vm.spouseDob),callback:function ($$v) {_vm.spouseDob=$$v},expression:"spouseDob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Hometown","rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Hometown","error-messages":errors,"outlined":""},model:{value:(_vm.spouseHometown),callback:function ($$v) {_vm.spouseHometown=$$v},expression:"spouseHometown"}})]}}],null,false,3969303062)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 1","rules":{
                    required: true,
                    digits: 10,
                    regex: '^(0)\\d{9}$',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Phone number 1","error-messages":errors,"outlined":""},model:{value:(_vm.spouseNumber1),callback:function ($$v) {_vm.spouseNumber1=$$v},expression:"spouseNumber1"}})]}}],null,false,3132915498)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 2","rules":{
                    regex: _vm.spouseNumber2 ? '^(0)\\d{9}$' : '',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Phone number 2 (optional)","error-messages":errors,"outlined":""},model:{value:(_vm.spouseNumber2),callback:function ($$v) {_vm.spouseNumber2=$$v},expression:"spouseNumber2"}})]}}],null,false,454597349)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","label":"Address","error-messages":errors},model:{value:(_vm.spouseAddress),callback:function ($$v) {_vm.spouseAddress=$$v},expression:"spouseAddress"}})]}}],null,false,1086268598)})],1)],1)],1):_vm._e()])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('validation-provider',{attrs:{"name":"Child","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","hide-details":"auto","items":_vm.birthBool,"label":"Child/Children","error-messages":errors},model:{value:(_vm.birthStatus),callback:function ($$v) {_vm.birthStatus=$$v},expression:"birthStatus"}})]}}])})],1)],1),_c('v-slide-y-transition',[(_vm.showChildren)?_c('div',{staticClass:"mt-3"},[_vm._l((_vm.basketChildren),function(task,i){return [_c('v-row',{key:i,attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":task.name,"rules":{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","label":"Name of child","error-messages":errors},model:{value:(task.name),callback:function ($$v) {_vm.$set(task, "name", $$v)},expression:"task.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":task.dob,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(task.dob),callback:function ($$v) {_vm.$set(task, "dob", $$v)},expression:"task.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(task.menuDob),callback:function ($$v) {_vm.$set(task, "menuDob", $$v)},expression:"task.menuDob"}},[_c('v-date-picker',{on:{"input":function($event){task.menuDob = false}},model:{value:(task.dob),callback:function ($$v) {_vm.$set(task, "dob", $$v)},expression:"task.dob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{attrs:{"name":task.birthplace,"rules":{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","error-messages":errors,"label":"Birthplace"},model:{value:(task.birthplace),callback:function ($$v) {_vm.$set(task, "birthplace", $$v)},expression:"task.birthplace"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{attrs:{"name":task.relationship,"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","error-messages":errors,"label":"Relationship"},model:{value:(task.relationship),callback:function ($$v) {_vm.$set(task, "relationship", $$v)},expression:"task.relationship"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"2"}},[_c('v-slide-y-reverse-transition',[(i + 1 === _vm.basketChildren.length)?_c('v-btn',{attrs:{"icon":"","dark":"","color":"success","disabled":!_vm.valid},on:{"click":function($event){return _vm.newRow()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus-circle")])],1):_vm._e()],1),_c('v-slide-y-reverse-transition',[(i + 1 >= 2)?_c('v-btn',{attrs:{"dark":"","icon":"","color":"red"},on:{"click":function($event){return _vm.removeRow(i)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete-outline ")])],1):_vm._e()],1)],1)],1)]})],2):_vm._e()])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[(!_vm.props.user_id)?_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.sendData},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Saving...")]),_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,false,776031462)},[_vm._v(" Save and Continue ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addNewSpouseChildren}},[_vm._v(" save changes ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }