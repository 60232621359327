<template>
  <v-card outlined>
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent ref="form1">
          <v-row>
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" lg="12">
                  <v-toolbar class="secondary--text" color="primary">
                    PERSONAL DETAILS
                  </v-toolbar>
                </v-col>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <v-select
                      v-model="title"
                      outlined
                      :items="titleArray"
                      :error-messages="errors"
                      class="rounded-0"
                      hide-details="auto"
                      label="Title"
                      data-vv-name="select"
                      required
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      hide-details="auto"
                      v-model="gender"
                      :items="genderArray"
                      outlined
                      class="rounded-0"
                      label="Gender"
                      :error-messages="errors"
                      data-vv-name="Gender"
                      required
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Other Names"
                    rules="required|alpha_spaces"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Other Names"
                      class="rounded-0"
                      outlined
                      v-model="othernames"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Last Name"
                      v-model="lastname"
                      :error-messages="errors"
                      required
                      class="rounded-0"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Date of birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="dob"
                          label="Date of Birth"
                          class="rounded-0"
                          readonly
                          outlined
                          hide-details="auto"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="dob"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hometown"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Hometown"
                      class="rounded-0"
                      outlined
                      v-model="hometown"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nationality"
                    rules="required"
                  >
                    <v-autocomplete
                      hide-details="auto"
                      label="Nationality"
                      class="rounded-0"
                      outlined
                      v-model="nationality"
                      :items="nationalities"
                      item-text="nationality"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" lg="12">
                  <v-toolbar class="secondary--text" color="primary">
                    CONTACT DETAILS
                  </v-toolbar>
                </v-col>
                <!--  <v-col cols="12">
                <v-hover v-slot="{ hover }" style="cursor: pointer !important">
                  <v-card
                    :elevation="hover ? 4 : 2"
                    :class="{ 'on-hover': hover }"
                    @click="triggerUpload"
                    class="mt-0"
                  >
                    <input
                      class="d-none"
                      ref="uploader"
                      type="file"
                      accept="image/*"
                      @change="uploadImage"
                    />
                    <v-img aspect-ratio="1.07" contain :src="image">
                      <v-row
                        class="fill-height flex-column"
                        align="center"
                        justify="center"
                      >
                        <p
                          v-if="!image"
                          :class="{ 'show-btns': hover }"
                          class="title text-center px-4 text-wrap"
                        >
                          CLICK TO ADD PROFILE PICTURE
                        </p>
                      </v-row>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col> -->
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile number"
                    :rules="{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      outlined
                      class="rounded-0"
                      label="Mobile Number"
                      :error-messages="errors"
                      v-model="mobileNumber"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Office number"
                    :rules="{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Office Number"
                      v-model="officeNumber"
                      validate-on-blur
                      class="rounded-0"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Personal Email"
                    rules="required|email"
                  >
                    <v-text-field
                      hide-details="auto"
                      class="rounded-0"
                      label="Personal Email"
                      v-model="personEmail"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Corporate Email"
                    rules="required|alpha_dash|alpha_spaces"
                    ref="uniqueCorporateEmail"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Corporate Email"
                      :error-messages="errors"
                      :messages="checkingText"
                      @keyup="ifIdUnique"
                      class="rounded-0"
                      v-model="corporateEmail"
                      outlined
                      suffix="@gctu.edu.gh"
                    >
                      <template slot="append" v-if="checkingText">
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-textarea
                      label="Personal Postal Address"
                      outlined
                      class="rounded-0"
                      auto-grow
                      v-model="address"
                      :error-messages="errors"
                      hide-details="auto"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>

    <v-card-actions>
      <v-btn
        type="submit"
        class="ma-2"
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="sendData"
      >
        Save and Continue
        <template v-slot:loader>
          <span>Saving...</span>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs, watch } from "vue";
  import nationalities from "@/apis/nationalities";
  import { required, email, regex, alpha_spaces } from "vee-validate/dist/rules";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Api from "@/apis/Api";
  import debounce from "@/debounce/debounce";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} is not valid",
  });

  extend("email", {
    ...email,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    setup(props, context) {
      const { userGeneral } = useActions(["userGeneral"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const generalForm = reactive({
        observer: null,
        othernames: null,
        lastname: null,
        title: null,
        select: null,
        gender: null,
        titleArray: ["Mr.", "Mrs.", "Miss.", "Dr.", "Prof."],
        genderArray: ["Male", "Female"],
        hometown: null,
        dob: null,
        valid: true,
        nationality: "",
        transparent: "rgba(255, 255, 255, 0)",
        image: null,
        uploader: null,
        menu: false,
        profilePicture: null,
        error: false,
        sheet: false,
        nationalities: nationalities ?? [],
        success: false,
        id: 0,
        mobileNumber: null,
        officeNumber: null,
        personEmail: null,
        corporateEmail: null,
        address: null,
        checkingText: null,
        uniqueCorporateEmail: null,
        loading: false,
      });

      const {
        loading,
        mobileNumber,
        officeNumber,
        personEmail,
        address,
        checkingText,
        corporateEmail,
        uniqueCorporateEmail,
        id,
        observer,
        uploader,
        image,
        title,
        gender,
        othernames,
        lastname,
        dob,
        profilePicture,
        error,
        sheet,
        nationality,
        success,
      } = toRefs(generalForm);

      watch(
        () => title.value,
        (title) => {
          switch (title) {
            case "Miss.":
              gender.value = "Female";
              break;
            case "Mr.":
              gender.value = "Male";
              break;
            case "Mrs.":
              gender.value = "Female";
              break;
            default:
              gender.value = null;
              break;
          }
        }
      );

      const uploadImage = (e) => {
        profilePicture.value = e.target.files[0];
        if (!profilePicture.value.type.match("image/*")) {
          alert("Choose an image file");
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(profilePicture.value);
        reader.onload = (e) => {
          image.value = e.target.result;
        };
      };

      const triggerUpload = () => {
        uploader.value.click();
      };

      const sendData = async () => {
        success.value = false;
        await observer.value.validate("form1").then((result) => {
          if (result) {
            loading.value = true;
            let data = {
              id: id.value,
              title: title.value,
              gender: gender.value,
              othernames: othernames.value,
              lastname: lastname.value,
              dob: dob.value,
              nationality: nationality.value,
              personal_number: mobileNumber.value,
              office_number: officeNumber.value,
              personal_email: personEmail.value,
              corporate_email: `${corporateEmail.value}@gctu.edu.gh`,
              address: address.value,
            };
            userGeneral(data)
              .then(() => {
                id.value = getters_single_staff.value.id;
                success.value = true;
                context.emit("page", {
                  bool: true,
                  page: 0,
                  name: "general",
                });
              })
              .finally(() => (loading.value = false));
          }
          if (result === false) {
            context.emit("page", { bool: false, page: 0 });
            error.value = true;
            return;
          }
        });
      };

      const withoutProfilePix = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result === true) {
            let data = {
              title: title.value,
              gender: gender.value,
              othernames: othernames.value,
              lastname: lastname.value,
              dob: dob.value,
              nationality: nationality.value,
              pic: null,
            };
            context.emit("page", { bool: true, page: 0, data, name: "general" });
            sheet.value = false;
          } else {
            context.emit("page", { bool: false, page: 0 });
            error.value = true;
          }
        });
      };

      const ifIdUnique = debounce(async () => {
        checkingText.value = "checking availability...";
        await Api()
          .post("isemployeecorporateemailunique", {
            corporate_email: corporateEmail.value,
          })
          .then((res) => {
            if (res.data === 1) {
              checkingText.value = null;
              uniqueCorporateEmail.value.setErrors(
                `Employee email ${corporateEmail.value}, Already Exists...`
              );
              return;
            } else {
              uniqueCorporateEmail.value.reset();
              checkingText.value = null;
            }
          });
      });

      return {
        ...toRefs(generalForm),
        sendData,
        uploadImage,
        triggerUpload,
        withoutProfilePix,
        ifIdUnique,
      };
    },
  });
</script>
<style scoped>
  .v-card {
    transition: opacity 0.4s ease-in-out;
  }

  /* .v-card:hover(.has-error) {
                                                                                                                                                        border: 1px solid red;
                                                                                                                                                      } */

  .show-btns {
    color: rgb(0, 46, 105) !important;
    cursor: pointer !important;
  }
</style>
